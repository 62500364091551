export interface IChangeStamp {
  channel: ChannelString;
  userId: string;
  by: string;
  timestamp: string;
}

export enum ChannelString {
  PORTAL = 'PORTAL',
  SMART_UI = 'SMART_UI',
  ONESDK = 'ONESDK',
  API = 'API',
}
