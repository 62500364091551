import { Primitive } from 'type-fest';
import type { OCRStatus } from '../../../frankie-client/clients/OCRClient';
import { Core } from './Core';
import { TValidation } from './TValidation';
import { ICloneable, Nullable } from './general';
import { DateTime } from 'luxon';

type TFileUploadUuid = string;
export type TScan = {
  scanId: Nullable<TFileUploadUuid>;
  fileUploadUuid?: Nullable<string>;
  file?: Nullable<string>;
  mimeType?: Nullable<string>;
  side?: Nullable<string>;
  scanCreated?: Nullable<string>;
  scanName?: Nullable<string>;
};
type TDocumentValidation = {
  manual: {
    isValid: Nullable<boolean>;
  };
  electronic: {
    // this comes from onfito
    validationReport: Nullable<Scan>;
    isValid: Nullable<boolean>;
  };
};
type TDocumentVerification = {
  electronic: Nullable<boolean>;
  manual: Nullable<boolean>;
};
export interface IDocumentPayload {
  documentId: Nullable<string>;
  verified: TDocumentVerification;
  dateOfBirth: Nullable<string>;
  idType: Nullable<Core['enumIdType']>;
  idSubType: Nullable<string>;
  country: Nullable<string>;
  region: Nullable<string>;
  idNumber: Nullable<string>;
  idExpiry: Nullable<string>;
  gender: Nullable<Core['enumGender']>;
  extraData: { [field: string]: Nullable<string | boolean | number> };
  ocrResult: OCRResult;
  docScan: TScan[];
  scans: TScan[];
  validation: TDocumentValidation;
}
const normalizeIdType = (
  idType: Nullable<string>
): Nullable<Core['enumIdType']> => {
  const mapper = {
    MOBILE_PHONE: 'MSISDN',
  };
  if (!idType) return null;
  return mapper[idType] || idType;
};

export type AcceptableType = Exclude<Primitive, undefined | symbol>;
type OCRResult = {
  status?: OCRStatus;
  runDate?: string;
  mismatch?: string[];
} & Record<string, AcceptableType | AcceptableType[]>;
export class Document implements IDocumentPayload, ICloneable<Document> {
  documentId: Nullable<string> = null;
  verified: TDocumentVerification = {
    electronic: null,
    manual: null,
  };
  dateOfBirth: Nullable<string> = null;
  idType: Nullable<Core['enumIdType']> = null;
  idSubType: Nullable<string> = null;
  country: Nullable<string> = null;
  region: Nullable<string> = null;
  idNumber: Nullable<string> = null;
  idExpiry: Nullable<string> = null;
  extraData: { [field: string]: Nullable<string | boolean | number> } = {};
  ocrResult: OCRResult = {};
  gender: Nullable<Core['enumGender']> = null;
  scans: Scan[] = [];
  validation: TDocumentValidation = {
    manual: {
      isValid: null,
    },
    electronic: {
      // this comes from onfito
      validationReport: null, // pdf from onfito for document report
      isValid: null, // onfito decision if document is valid
    },
  };
  constructor();
  constructor(d: IDocumentConstructor);
  constructor(d?: IDocumentConstructor) {
    if (!d) return;
    this.documentId = d.documentId?.toLowerCase() || null;
    this.verified = {
      electronic: null,
      manual: null,
      ...d.verified,
    };
    this.dateOfBirth = d.dateOfBirth;
    this.idType = normalizeIdType(d.idType);
    this.idSubType = d.idSubType;
    this.country = d.country || 'AUS';
    this.region = d.region;
    this.idNumber = d.idNumber;
    this.gender = d.gender;
    this.extraData = d.extraData || {};
    this.scans = d.scans || [];
    this.idExpiry = d.idExpiry
      ? DateTime.fromISO(d.idExpiry).toFormat('yyyy-LL-dd')
      : null;
    this.validation = {
      manual: {
        isValid: d.validation?.manual?.isValid || null,
      },
      electronic: {
        validationReport: d.validation?.electronic?.validationReport || null,
        isValid: d.validation?.electronic?.isValid || null,
      },
    };
  }

  // keeping docScan for backwards compatibility
  get docScan(): Scan[] {
    return this.scans;
  }

  set docScan(v: Scan[]) {
    this.scans = v;
  }

  isNewDocument(): boolean {
    return !!this.documentId;
  }

  getUploadedScanUuids(): string[] {
    const uploadedScansFinder = (sc) => typeof sc.fileUploadUuid === 'string';
    const extractUploadUuid = (sc) => sc.fileUploadUuid;
    const uploadedUuid = this.scans
      .filter(uploadedScansFinder)
      .map(extractUploadUuid);
    return uploadedUuid;
  }
  includeUploadFile(uploadUuid: string, s: Scan) {
    const i = this.scans.findIndex((d) => d.fileUploadUuid === uploadUuid);
    this.scans[i] = s;
  }
  clone(): Document {
    const payload = JSON.parse(JSON.stringify(this));
    const instance = Document.fromJSON(payload);
    return instance;
  }
  toJSON(): IDocumentPayload {
    const {
      scans,
      dateOfBirth,
      idSubType,
      documentId,
      idType,
      verified,
      region,
      country,
      idExpiry,
      idNumber,
      gender,
      extraData,
      validation,
      ocrResult,
    } = this;

    return {
      dateOfBirth: dateOfBirth || null,
      idSubType: idSubType || null,
      docScan: scans,
      scans,
      documentId,
      verified,
      region: region || null,
      country,
      idNumber: idNumber || null,
      gender: gender,
      extraData,
      validation,
      idType: idType || null,
      idExpiry,
      ocrResult,
    };
  }

  static fromJSON(payload: IDocumentPayload): Document {
    const document = new Document();
    Object.keys(payload).forEach((key) => {
      //idExpiry need to has the format YYYY-MM-DD
      if (key === 'idExpiry' && payload[key])
        return (document[key] = DateTime.fromISO(
          payload[key] as string
        ).toFormat('yyyy-LL-dd'));
      document[key] = payload[key];
    });
    return document;
  }
  static default(): Document {
    return new Document();
  }
}
export class Scan {
  scanId: Nullable<string>;
  fileUploadUuid: Nullable<string>;
  file: Nullable<string>;
  mimeType: Nullable<string>;
  side: Nullable<string>;
  scanCreated: Nullable<string>;
  scanName: Nullable<string>;
  constructor(scan: TScan) {
    this.scanId = scan.scanId;
    this.fileUploadUuid = scan.fileUploadUuid || null;
    this.file = scan.file || null;
    this.mimeType = scan.mimeType || null;
    this.side = scan.side || 'F';
    this.scanCreated = scan.scanCreated || null;
    this.scanName = scan.scanName || null;
  }
  toJSON(): TScan {
    return { ...this };
  }
  static fromJSON(payload: TScan): Scan {
    return new Scan(payload);
  }
}

export interface IDocumentConstructor {
  documentId: string | null;
  verified?: {
    electronic: TValidation;
    manual: TValidation;
  };
  dateOfBirth: string | null;
  idType: Core['enumIdType'];
  idSubType: string | null;
  country: string | null;
  region: string | null;
  idNumber: string | null;
  idExpiry: string | null;
  gender: Core['enumGender'] | null;
  extraData?: { [field: string]: Nullable<string | boolean | number> };
  scans?: Scan[]; // [{ file: "", mimeType: "image/jpeg", side:"F | B" } OR string with file upload uuid ]
  validation?: {
    manual: {
      isValid: TValidation;
    };
    electronic: {
      // this comes from onfito
      validationReport: Scan | null;
      isValid: TValidation;
    };
  };
}
