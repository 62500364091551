import BiometricsModule from '@module/biometrics';
import BusinessModule from '@module/business';
import type {
  BrandedInitialiseFunction,
  ExtractDefinitions,
  ModuleTypeSystem,
} from '@module/common/types';
import DeviceModule from '@module/device';
import FederationModule from '@module/federation';
import FormModule from '@module/form';
import IDVModule from '@module/idv';
import IndividualModule from '@module/individual';
import OCRModule from '@module/ocr';
import SessionModule from '@module/session';
import WorkflowModule from '@module/workflow';

/**
 * ADDING NEW MODULES
 * 0) Choose an appropriate simple kebab-case name for your module. Ideally just a single word.
 *    This name will be used by developers to initialise your module.
 *    ie: component("biometrics"), or flow("idv")
 *
 * 1) Define the module
 * 1.0) Create a folder with the same name as the module {module}
 * 1.1) In the file "{module}/definition.ts", create create a type definition "{module}Module" for your module using the type "ModuleDefinition",
 * where you will provide the chosen name, among other options.
 * 1.2) Define the initialisation function for your module and pass it to the "defineModule" function, along with the "ModuleDefinition" type parameter.
 * That function will add additional commmon functionality and enforce typing. Export the result of that function as the default export of the file.
 *
 * PS) The "defineModule" function will also "Brand" the initialise function with the ModuleDefinition, so the function will always carry the ModuleDefinition type with it, which can be retrieved with ExtractDefinition<typeof initialiseFunction>
 *
 * @example <caption>Example of a module definition</caption>
 * export default defineModule<ModuleDefinition>("module-name", (globalState, customOptions) => {
 *  // Do module things here
 *  // Return the module context object
 *  return { ... }
 * });
 *
 * 2) Add it to the global Modules configuration
 * Across the entire OneSDK, for the module name to be recognised as a valid and instantiable module, it needs to be part of the OneSDK modules type system.
 * The "ModuleDefinition" for each module can be extracted from the dictionary "ConfiguredModules"
 * The type system uses the "ConfiguredModules" to categorise and extract all sorts of particularities for each module. Interpreting them and making a summary available throughout the SDK from a single type called "Modules".
 * 2.1) Import the initialise function and add it to the Modules object below.
 *
 * TLDR:
 * - Define the module and add it to the global configuration below.
 * - The type "Modules" works as a global namespace for all types inferred from all the modules included in the dictionary.
 */
const Modules = {
  business: BusinessModule,
  biometrics: BiometricsModule,
  device: DeviceModule,
  ocr: OCRModule,
  individual: IndividualModule,
  session: SessionModule,
  federation: FederationModule,
  idv: IDVModule,
  form: FormModule,
  workflow: WorkflowModule,
};

export type ConfiguredModules = ExtractDefinitions<typeof Modules>;
export type Modules = ModuleTypeSystem<ConfiguredModules>;
/**
 * Helper function to retrieve the initialise function for a module
 */
export const getInitialiser = <Name extends Modules['moduleName']>(
  name: Name,
) => {
  const initialiser = Modules[name] as BrandedInitialiseFunction;
  if (!initialiser)
    throw new Error(
      `Initialiser for '${name}' was not found. Make sure the module was correctly defined`,
    );

  return initialiser as BrandedInitialiseFunction<ConfiguredModules[Name]>;
};
