import get from 'lodash.get';

import type { Get, Paths } from 'type-fest';

const features = {
  /** Configure eventHub to include stack trace of the event emission.
   * When this value is 'true' it will break multiple tests that don't expect an extra parameter to be emitted with events.
   * * Simply turn it off once you're done debugging. If this becomes a deployed feature, we'll need to fix the tests */
  includeStackTraceOnEvents: false,
  modules: {
    ocr: {
      /**
       * If a vendor UI doesn't present a review screen, the ocr module
       * might choose to present a custom one (using the Form module)
       * For each vendor, determine if their UI contains a review screen and set it below.
       * This flag, along with the recipe configuration provideReviewScreen,
       * will determine whether the Form is supposed to be included
       **/
      containsReviewScreen: {
        incode: false,
        onfido: false,
        ocrlabs: false, // ocrlabs is headless
      },
    },
    idv: {
      /**
       * If a vendor UI doesn't present a review screen, the idv module
       * might choose to present a custom one (using the Form module)
       * For each vendor, determine if their UI contains a review screen and set it below.
       * This flag, along with the recipe configuration provideReviewScreen,
       * will determine whether the Form is supposed to be included
       **/
      containsReviewScreen: {
        mastercard: false,
        incode: false,
        ocrlabs: true,
      },
    },
    form: {
      wrappingMode: 'div' as 'shadow' | 'iframe' | 'div',
      font: {
        cdnUrl: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap',
        // Provide a string to be used as CSS rule,
        // Remember to quote names containing spaces and to add fallback fonts separated with comma
        // ie: "'Open sans', sans-serif"
        fontFamilyCSSRule: `Inter, sans-serif, Serif`,
      },
    },
  },
  /** Determine default values for missing fields in the recipe configuration object */
  recipeDefaults: {
    ocr: {
      provideReviewScreen: false,
    },
    idv: {
      provideReviewScreen: false,
    },
  },
} as const;

/**
 * Helper function to access nested properties of the features object
 * @param dotPath - path to the property, e.g. 'modules.ocr.containsReviewScreen.incode'
 */
export function feat<const Path extends Paths<Features>, Type = Feature<Path>>(dotPath: Path): Type {
  return get(features, dotPath) as Type;
}

type Features = typeof features;
type Feature<P extends Paths<Features>> = Get<Features, P>;
