import type { EventHub, ModuleVendorLoader } from '@module/common';
import { OneSDKError } from '@module/common';
import type { GlobalEvents } from '@module/sdk/types';
import type { OneSdkContext } from '@module/types';

import type { IDVModule } from '..';

type Dependencies = {
  globalEventHub: EventHub<GlobalEvents>;
  eventHub: EventHub<IDVModule['moduleEvents']>;
  recipe: IDVModule['recipeConfiguration'];
  oneSdkInstance: OneSdkContext;
  vendorLoader: ModuleVendorLoader<IDVModule>;
};

export function mkMountMethod(deps: Dependencies): IDVModule['moduleContext']['mount'] {
  const { eventHub, recipe, oneSdkInstance } = deps;
  const vendorName = recipe.idv.provider.name;

  return (domElementOrSelector: HTMLElement | string) => {
    // If individual isn't persisted, submit to persist changes first
    // This will allow any changes to be persisted before the IDV is mounted
    const asyncMount = async () => {
      // TODO: this might be needed in the biometrics component too. Investigate.
      const individual = oneSdkInstance.individual();
      // Use case: individual is preloaded and changes were made to them before running idv
      // Store first and than run idv
      if (!individual.isPersisted()) await individual.submit();
      loadAndInitialiseVendor(deps, domElementOrSelector);
    };
    // return void, so mount doesn't return a promise
    // TODO: Why not? We need to stabilish a standard.
    return void asyncMount().catch((error) => {
      eventHub.emit('error', new OneSDKError(error.message, error));
      eventHub.emit('loading', false, { message: `Failed while loading IDV vendor '${vendorName}'` });
    });
  };
}

const loadAndInitialiseVendor = (
  deps: Dependencies,
  domElementOrSelector: HTMLElement | string,
) => {
  const { vendorLoader, eventHub, globalEventHub } = deps;
  // Load and initialise vendor wrapper
  // Which will automatically mount camera feed to HTML element provided as parameter above
  vendorLoader({
    vendorWrapperOptions: () => {
      // Resolve the target HTML Element
      const mountElement =
        typeof domElementOrSelector === 'string'
          ? window.document.querySelector<HTMLElement>(domElementOrSelector)
          : domElementOrSelector;
      if (!mountElement) throw new Error(`DOM Element ${domElementOrSelector} not found.`);

      return {
        eventHub,
        mountElement,
      };
    },
    onSuccess: (_, { vendorName }) => {
      globalEventHub.emit('telemetry', {
        eventName: 'IDV:MOUNT',
        data: { vendor: vendorName },
      });
    },
    onError: (error, { vendorName }) => {
      globalEventHub.emit('telemetry', {
        eventName: 'IDV:MOUNT:ERROR',
        data: { vendor: vendorName },
        error,
      });
    },
  });
};
