import type { Modules } from '@config';
import type { Applicant } from '@module/common/shared/models/Applicant';
import type { Document } from '@module/common/shared/models/Document';

import { type DocumentTypes } from './vendors/react/appConfig.type';
import { React_Form_IDS } from './vendors/react/types/pages.type';

import type {
  ConfigCategory,
  FormModule,
  FormRecipeOptions,
} from './definition';
import type {
  LegacyUIResultPayload,
  LegacyUIScreenPayload,
} from './vendors/legacy/legacyUIEvents.types';
import type { PartialDeep } from 'type-fest';

type Vendor = FormModule['vendorName'];

export type EventPayload = {
  componentName?: Modules['moduleName'];
  provider?: Vendor;
  inputInfo: {
    name: React_Form_IDS;
    type?: ConfigCategory;
    documentType?: DocumentTypes | DocumentTypes[];
    index?: number;
  };
};

/**
 * Event mapping for each screen,
 * ready: when the screen is ready to be displayed
 * loaded: when the screen is loaded
 * failed: when the screen failed to load
 */
export type ScreenEventType = {
  ready: keyof FormEvents;
  loaded: keyof FormEvents;
  failed: keyof FormEvents;
  success?: keyof FormEvents;
  partial?: keyof FormEvents;
  pending?: keyof FormEvents;
  back?: keyof FormEvents;
  navigate?: keyof FormEvents;
};

type FormEventKeyType = `form:${React_Form_IDS}:${keyof ScreenEventType}`;

export const FORM_WELCOME_READY: FormEventKeyType = `form:${React_Form_IDS.WELCOME}:ready`;
export const FORM_WELCOME_LOADED: FormEventKeyType = `form:${React_Form_IDS.WELCOME}:loaded`;
export const FORM_WELCOME_FAILED: FormEventKeyType = `form:${React_Form_IDS.WELCOME}:failed`;

export const FORM_CONSENT_READY: FormEventKeyType = `form:${React_Form_IDS.CONSENT}:ready`;
export const FORM_CONSENT_LOADED: FormEventKeyType = `form:${React_Form_IDS.CONSENT}:loaded`;
export const FORM_CONSENT_FAILED: FormEventKeyType = `form:${React_Form_IDS.CONSENT}:failed`;

export const FORM_DOCUMENT_READY: FormEventKeyType = `form:${React_Form_IDS.DOCUMENT}:ready`;
export const FORM_DOCUMENT_LOADED: FormEventKeyType = `form:${React_Form_IDS.DOCUMENT}:loaded`;
export const FORM_DOCUMENT_FAILED: FormEventKeyType = `form:${React_Form_IDS.DOCUMENT}:failed`;
export const FORM_DOCUMENT_BACK: FormEventKeyType = `form:${React_Form_IDS.DOCUMENT}:back`;
export const FORM_DOCUMENT_NAVIGATE: FormEventKeyType = `form:${React_Form_IDS.DOCUMENT}:navigate`;

export const FORM_REVIEW_READY: FormEventKeyType = `form:${React_Form_IDS.REVIEW}:ready`;
export const FORM_REVIEW_LOADED: FormEventKeyType = `form:${React_Form_IDS.REVIEW}:loaded`;
export const FORM_REVIEW_FAILED: FormEventKeyType = `form:${React_Form_IDS.REVIEW}:failed`;
export const FORM_REVIEW_SUCCESS: FormEventKeyType = `form:${React_Form_IDS.REVIEW}:success`;
export const FORM_REVIEW_PARTIAL: FormEventKeyType = `form:${React_Form_IDS.REVIEW}:partial`;
export const FORM_REVIEW_PENDING: FormEventKeyType = `form:${React_Form_IDS.REVIEW}:pending`;

export const FORM_PERSONAL_DETAILS_READY: FormEventKeyType = `form:${React_Form_IDS.PERSONAL}:ready`;
export const FORM_PERSONAL_DETAILS_LOADED: FormEventKeyType = `form:${React_Form_IDS.PERSONAL}:loaded`;
export const FORM_PERSONAL_DETAILS_FAILED: FormEventKeyType = `form:${React_Form_IDS.PERSONAL}:failed`;

export const FORM_RESULT_READY: FormEventKeyType = `form:${React_Form_IDS.RESULT}:ready`;
export const FORM_RESULT_LOADED: FormEventKeyType = `form:${React_Form_IDS.RESULT}:loaded`;
export const FORM_RESULT_FAILED: FormEventKeyType = `form:${React_Form_IDS.RESULT}:failed`;
export const FORM_RESULT_SUCCESS: FormEventKeyType = `form:${React_Form_IDS.RESULT}:success`;
export const FORM_RESULT_PARTIAL: FormEventKeyType = `form:${React_Form_IDS.RESULT}:partial`;
export const FORM_RESULT_PENDING: FormEventKeyType = `form:${React_Form_IDS.RESULT}:pending`;

export const FORM_RETRY_READY: FormEventKeyType = `form:${React_Form_IDS.RETRY}:ready`;
export const FORM_RETRY_LOADED: FormEventKeyType = `form:${React_Form_IDS.RETRY}:loaded`;
export const FORM_RETRY_FAILED: FormEventKeyType = `form:${React_Form_IDS.RETRY}:failed`;

export const FORM_DOC_UPLOAD_READY: FormEventKeyType = `form:${React_Form_IDS.DOC_UPLOAD}:ready`;
export const FORM_DOC_UPLOAD_LOADED: FormEventKeyType = `form:${React_Form_IDS.DOC_UPLOAD}:loaded`;
export const FORM_DOC_UPLOAD_SUCCESS: FormEventKeyType = `form:${React_Form_IDS.DOC_UPLOAD}:success`;
export const FORM_DOC_UPLOAD_FAILED: FormEventKeyType = `form:${React_Form_IDS.DOC_UPLOAD}:failed`;
export const FORM_DOC_UPLOAD_NAVIGATE: FormEventKeyType = `form:${React_Form_IDS.DOC_UPLOAD}:navigate`;

export type FormEvents = {
  loaded: [{ domElement: HTMLElement; provider: string }];
  ready: [
    {
      domElement: HTMLElement;
      config: PartialDeep<FormRecipeOptions['provider']['config']>;
    },
  ];
  [FORM_WELCOME_READY]: [EventPayload];
  [FORM_WELCOME_LOADED]: [EventPayload];
  [FORM_WELCOME_FAILED]: [EventPayload];

  [FORM_CONSENT_READY]: [EventPayload];
  [FORM_CONSENT_LOADED]: [EventPayload];
  [FORM_CONSENT_FAILED]: [EventPayload];

  [FORM_DOCUMENT_READY]: [EventPayload];
  [FORM_DOCUMENT_LOADED]: [EventPayload];
  [FORM_DOCUMENT_FAILED]: [EventPayload];
  [FORM_DOCUMENT_BACK]: [EventPayload];
  [FORM_DOCUMENT_NAVIGATE]: [EventPayload];

  [FORM_REVIEW_READY]: [EventPayload];
  [FORM_REVIEW_LOADED]: [EventPayload];
  [FORM_REVIEW_FAILED]: [EventPayload];
  [FORM_REVIEW_SUCCESS]: [EventPayload];
  [FORM_REVIEW_PARTIAL]: [EventPayload];
  [FORM_REVIEW_PENDING]: [EventPayload];

  [FORM_PERSONAL_DETAILS_READY]: [EventPayload];
  [FORM_PERSONAL_DETAILS_LOADED]: [EventPayload];
  [FORM_PERSONAL_DETAILS_FAILED]: [EventPayload];

  [FORM_RESULT_READY]: [EventPayload];
  [FORM_RESULT_LOADED]: [EventPayload];
  [FORM_RESULT_FAILED]: [EventPayload];
  [FORM_RESULT_SUCCESS]: [EventPayload];
  [FORM_RESULT_PARTIAL]: [EventPayload];
  [FORM_RESULT_PENDING]: [EventPayload];

  [FORM_RETRY_READY]: [EventPayload];
  [FORM_RETRY_LOADED]: [EventPayload];
  [FORM_RETRY_FAILED]: [EventPayload];

  [FORM_DOC_UPLOAD_READY]: [EventPayload];
  [FORM_DOC_UPLOAD_LOADED]: [EventPayload];
  [FORM_DOC_UPLOAD_SUCCESS]: [EventPayload];
  [FORM_DOC_UPLOAD_FAILED]: [EventPayload];
  [FORM_DOC_UPLOAD_NAVIGATE]: [EventPayload];

  'form:document_type_select:ready': [LegacyUIScreenPayload];
  'form:drivers_license_input:ready': [LegacyUIScreenPayload];
  'form:national_id:ready': [LegacyUIScreenPayload];
  'form:medicare_input:ready': [LegacyUIScreenPayload];
  'form:passport_input:ready': [LegacyUIScreenPayload];
  'form:name_input:ready': [LegacyUIScreenPayload];
  'form:dob_input:ready': [LegacyUIScreenPayload];
  'form:address_input:ready': [LegacyUIScreenPayload];
  'form:details_summary:ready': [LegacyUIScreenPayload];
  'form:failure:ready': [LegacyUIScreenPayload];
  'form:success:ready': [LegacyUIScreenPayload];
  'form:no_match:ready': [LegacyUIScreenPayload];
  'form:pending_verification:ready': [LegacyUIScreenPayload];
  'form:error:ready': [LegacyUIScreenPayload];
  'form:name_input_check:ready': [LegacyUIScreenPayload];
  'form:dob_input_check:ready': [LegacyUIScreenPayload];
  'form:manual_address_input:ready': [LegacyUIScreenPayload];
  'form:additional_address_input:ready': [LegacyUIScreenPayload];
  'form:address_input_check:ready': [LegacyUIScreenPayload];
  results: [
    | LegacyUIResultPayload
    | {
        documents: Document[];
        applicant: Applicant;
        entityId: string;
      },
  ];
  destroy: [];
  navigation: [{ page: string }];
  builtin_loaded: EventPayload[];
  builtin_ready: EventPayload[];
  builtin_failed_loading: { message?: string }[];
};

export const FormTelemetryDict: Record<keyof FormEvents, string> = {
  loaded: 'FORM:LOADED',
  ready: 'FORM:READY',
  [FORM_WELCOME_READY]: 'FORM:WELCOME:READY',
  [FORM_WELCOME_LOADED]: 'FORM:WELCOME:LOADED',
  [FORM_WELCOME_FAILED]: 'FORM:WELCOME:FAILED',

  [FORM_CONSENT_READY]: 'FORM:CONSENT:READY',
  [FORM_CONSENT_LOADED]: 'FORM:CONSENT:LOADED',
  [FORM_CONSENT_FAILED]: 'FORM:CONSENT:FAILED',

  [FORM_DOCUMENT_READY]: 'FORM:DOCUMENT:READY',
  [FORM_DOCUMENT_LOADED]: 'FORM:DOCUMENT:LOADED',
  [FORM_DOCUMENT_FAILED]: 'FORM:DOCUMENT:FAILED',
  [FORM_DOCUMENT_BACK]: 'FORM:DOCUMENT:BACK',
  [FORM_DOCUMENT_NAVIGATE]: 'FORM:DOCUMENT:NAVIGATE',

  [FORM_REVIEW_READY]: 'FORM:REVIEW:READY',
  [FORM_REVIEW_LOADED]: 'FORM:REVIEW:LOADED',
  [FORM_REVIEW_FAILED]: 'FORM:REVIEW:FAILED',
  [FORM_REVIEW_SUCCESS]: 'FORM:REVIEW:SUCCESS',
  [FORM_REVIEW_PARTIAL]: 'FORM:REVIEW:PARTIAL',
  [FORM_REVIEW_PENDING]: 'FORM:RESULT:PENDING',

  [FORM_PERSONAL_DETAILS_READY]: 'FORM:PERSONAL:READY',
  [FORM_PERSONAL_DETAILS_LOADED]: 'FORM:PERSONAL:LOADED',
  [FORM_PERSONAL_DETAILS_FAILED]: 'FORM:PERSONAL:FAILED',

  [FORM_RESULT_READY]: 'FORM:RESULT:READY',
  [FORM_RESULT_LOADED]: 'FORM:RESULT:LOADED',
  [FORM_RESULT_FAILED]: 'FORM:RESULT:FAILED',
  [FORM_RESULT_SUCCESS]: 'FORM:RESULT:SUCCESS',
  [FORM_RESULT_PARTIAL]: 'FORM:RESULT:PARTIAL',
  [FORM_RESULT_PENDING]: 'FORM:RESULT:PENDING',

  [FORM_RETRY_READY]: 'FORM:RETRY:READY',
  [FORM_RETRY_LOADED]: 'FORM:RETRY:LOADED',
  [FORM_RETRY_FAILED]: 'FORM:RETRY:FAILED',

  [FORM_DOC_UPLOAD_READY]: 'FORM:DOC_UPLOAD:READY',
  [FORM_DOC_UPLOAD_LOADED]: 'FORM:DOC_UPLOAD:LOADED',
  [FORM_DOC_UPLOAD_SUCCESS]: 'FORM:DOC_UPLOAD:SUCCESS',
  [FORM_DOC_UPLOAD_FAILED]: 'FORM:DOC_UPLOAD:FAILED',
  [FORM_DOC_UPLOAD_NAVIGATE]: 'FORM:DOC_UPLOAD:NAVIGATE',

  'form:document_type_select:ready': 'FORM:DOCUMENT_TYPE_SELECT:READY',
  'form:drivers_license_input:ready': 'FORM:DRIVERS_LICENSE_INPUT:READY',
  'form:national_id:ready': 'FORM:NATIONAL_ID:READY',
  'form:medicare_input:ready': 'FORM:MEDICARE_INPUT:READY',
  'form:passport_input:ready': 'FORM:PASSPORT_INPUT:READY',
  'form:name_input:ready': 'FORM:NAME_INPUT:READY',
  'form:dob_input:ready': 'FORM:DOB_INPUT:READY',
  'form:address_input:ready': 'FORM:ADDRESS_INPUT:READY',
  'form:details_summary:ready': 'FORM:DETAILS_SUMMARY:READY',
  'form:failure:ready': 'FORM:FAILURE:READY',
  'form:success:ready': 'FORM:SUCCESS:READY',
  'form:no_match:ready': 'FORM:NO_MATCH:READY',
  'form:pending_verification:ready': 'FORM:PENDING_VERIFICATION:READY',
  'form:error:ready': 'FORM:ERROR:READY',
  'form:name_input_check:ready': 'FORM:NAME_INPUT_CHECK:READY',
  'form:dob_input_check:ready': 'FORM:DOB_INPUT_CHECK:READY',
  'form:manual_address_input:ready': 'FORM:MANUAL_ADDRESS_INPUT:READY',
  'form:additional_address_input:ready': 'FORM:ADDITIONAL_ADDRESS_INPUT:READY',
  'form:address_input_check:ready': 'FORM:ADDRESS_INPUT_CHECK:READY',
  results: 'FORM:RESULTS',
  destroy: 'FORM:DESTROY',
  navigation: 'FORM:NAVIGATION',
  builtin_loaded: 'FORM:BUILTIN_LOADED',
  builtin_ready: 'FORM:BUILTIN_READY',
  builtin_failed_loading: 'FORM:BUILTIN_FAILED_LOADING',
};

export const screenEventMaps: Partial<Record<React_Form_IDS, ScreenEventType>> =
  {
    welcome: {
      ready: FORM_WELCOME_READY,
      loaded: FORM_WELCOME_LOADED,
      failed: FORM_WELCOME_FAILED,
    },
    consent: {
      ready: FORM_CONSENT_READY,
      loaded: FORM_CONSENT_LOADED,
      failed: FORM_CONSENT_FAILED,
    },
    document: {
      ready: FORM_DOCUMENT_READY,
      loaded: FORM_DOCUMENT_LOADED,
      failed: FORM_DOCUMENT_FAILED,
      back: FORM_DOCUMENT_BACK,
      navigate: FORM_DOCUMENT_NAVIGATE,
    },
    review: {
      ready: FORM_REVIEW_READY,
      loaded: FORM_REVIEW_LOADED,
      failed: FORM_REVIEW_FAILED,
      success: FORM_REVIEW_SUCCESS,
      partial: FORM_REVIEW_PARTIAL,
      pending: FORM_REVIEW_PENDING,
    },
    personal: {
      ready: FORM_PERSONAL_DETAILS_READY,
      loaded: FORM_PERSONAL_DETAILS_LOADED,
      failed: FORM_PERSONAL_DETAILS_FAILED,
    },
    result: {
      ready: FORM_RESULT_READY,
      loaded: FORM_RESULT_LOADED,
      failed: FORM_RESULT_FAILED,
      success: FORM_RESULT_SUCCESS,
      partial: FORM_RESULT_PARTIAL,
      pending: FORM_RESULT_PENDING,
    },
    retry: {
      ready: FORM_RETRY_READY,
      loaded: FORM_RETRY_LOADED,
      failed: FORM_RETRY_FAILED,
    },
    doc_upload: {
      ready: FORM_DOC_UPLOAD_READY,
      loaded: FORM_DOC_UPLOAD_LOADED,
      success: FORM_DOC_UPLOAD_SUCCESS,
      failed: FORM_DOC_UPLOAD_FAILED,
      navigate: FORM_DOC_UPLOAD_NAVIGATE,
    },
  };
