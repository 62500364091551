import { DateObject, Nullable, TDOBTypeSupport } from "../models/general";

export const transformDateToGregorian = (input: Nullable<string>, type: TDOBTypeSupport): string => {
  let gregorianDate = input || "";
  switch (type) {
    // base on each type of date, we will add in another transformer
    case "buddhist":
      {
        gregorianDate = convertBuddhistToGregorian(input);
      }
      break;

    default:
      break;
  }
  return gregorianDate;
};
export const transformGregorianDateTo = (input: Nullable<string>, type: TDOBTypeSupport): string => {
  let outputDate = input || "";
  switch (type) {
    // base on each type of date, we will add in another transformer
    case "buddhist":
      {
        outputDate = convertGregorianToBuddhist(input);
      }
      break;

    default:
      break;
  }
  return outputDate;
};

export const breakDateToObject = (input: string | null): DateObject => {
  let dd: DateObject["dd"];
  let mm: DateObject["mm"];
  let yyyy: DateObject["yyyy"];
  if (input) {
    const match = input.match(/(.*)-(.*)-(.*)/);
    [yyyy, mm, dd] = match ? [match[1], match[2], match[3]] : [null, null, null];
  } else {
    dd = null;
    mm = null;
    yyyy = null;
  }
  return { dd, mm, yyyy };
};
export const serialiseDateObject = (obj: DateObject) => [obj.yyyy, obj.mm, obj.dd].join("-");

const YEAR_GAP_BUDDHIST_AND_GREGORIAN = 543;

const convertBuddhistToGregorian = (input: string | null): string => {
  if (!input) return serialiseDateObject({ yyyy: null, mm: null, dd: null });
  const buddhistDateObject = breakDateToObject(input);
  if (buddhistDateObject.dd === "00") buddhistDateObject.dd = "01";
  if (buddhistDateObject.mm === "00") buddhistDateObject.mm = "01";
  const { yyyy, dd, mm } = buddhistDateObject;

  const gregorianYear = yyyy ? `${+yyyy - YEAR_GAP_BUDDHIST_AND_GREGORIAN}` : null;
  return serialiseDateObject({ yyyy: gregorianYear, dd, mm });
};
const convertGregorianToBuddhist = (input: string | null): string => {
  if (!input) return serialiseDateObject({ yyyy: null, mm: null, dd: null });
  const gregorianDateObject = breakDateToObject(input);
  const { yyyy, dd, mm } = gregorianDateObject;
  const buddhistYear = yyyy ? `${+yyyy + YEAR_GAP_BUDDHIST_AND_GREGORIAN}` : null;
  return serialiseDateObject({ yyyy: buddhistYear, dd, mm });
};
