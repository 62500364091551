import { mkUpload } from '@module/business/actions/upload';
import { ReactiveStore } from '@module/common';
import { defineModule } from '@module/common/modules/defineModule';
import type { DocumentType } from '@module/common/shared/models/SupportingDocument';
import { BusinessClient } from '@module/frankie-client/clients/BusinessClient';
import { SupportingDocumentsClient } from '@module/frankie-client/clients/SupportingDocumentsClient';

import { subscribeAndEmitEvents } from './actions/emitEvents';
import { mkSearch } from './actions/search';

import type {
  BusinessModule,
  InternalState,
  PublicAccessors,
} from './definition';

export default defineModule<BusinessModule>('business', (globalState) => {
  const { frankieClient, localEventHub } = globalState;
  const clientBusiness = new BusinessClient(frankieClient);
  const clientSupportingDocuments = new SupportingDocumentsClient(
    frankieClient,
  );

  const state$ = new ReactiveStore<InternalState>({
    organization: null,
    isLoading: null,
  });

  const organization$ = state$.getRootAccessors('organization');
  const isLoading$ = state$.getRootAccessors('isLoading');

  const entityId$ = ReactiveStore.mkPropertyAccessors(organization$, {
    propertyName: 'entityId',
  });
  const details$ = ReactiveStore.mkPropertyAccessors(organization$, {
    propertyName: 'details',
  });
  const name$ = ReactiveStore.mkPropertyAccessors(details$, {
    propertyName: 'name',
  });
  const documents$ = ReactiveStore.mkPropertyAccessors(organization$, {
    propertyName: 'documents',
  });

  // when adding a new public field, make sure to update the PublicFields type in ./types/module
  const publicAccessors: PublicAccessors = {
    entityId: entityId$,
    name: name$,
    documents: documents$,
    isLoading: isLoading$,
  };

  // For each public field, emit "data_updated" when they change
  subscribeAndEmitEvents({
    emitDataUpdatedFor: [entityId$, details$],
    eventHub: localEventHub,
    organization$,
  });

  // methods
  const search = mkSearch({
    client: clientBusiness,
    eventHub: localEventHub,
    state$,
    recipeName: globalState.recipe.name,
  });

  const upload = mkUpload({
    client: clientSupportingDocuments,
    eventHub: localEventHub,
    state$,
  });

  return {
    // Fetch methods
    search,
    isLoading: () => isLoading$.getValue(),
    // Data methods
    access: (fieldName) => publicAccessors[fieldName],
    // Upload methods
    upload: (file: File, type: DocumentType) => upload(file, type),
  };
});

export * from './definition';
export * from './events';
