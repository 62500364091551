import type { EventHub, ReadonlyAccessors } from '@module/common';
import type { Organization_Profile } from '@module/common/shared/models/internal';

import type { Events } from '..';

type SubscribeAndEmitEventsDeps = {
  emitDataUpdatedFor: ReadonlyAccessors<unknown>[];
  eventHub: EventHub<Events>;
  organization$: ReadonlyAccessors<Organization_Profile>;
};
export const subscribeAndEmitEvents = (deps: SubscribeAndEmitEventsDeps) => {
  const { eventHub, emitDataUpdatedFor } = deps;
  // Emit "data_updated" for all accessors provided in `emitDataUpdatedFor`
  emitDataUpdatedFor.forEach((accessors) => {
    const fieldName = accessors.property;
    const observable = accessors.observable;
    observable.subscribe(() =>
      eventHub.emit('data_updated', fieldName, accessors.getValue()),
    );
  });
};
