import type { ModuleParameters } from '@module/common/modules/defineModule';
import { defineModule } from '@module/common/modules/defineModule';
import type { ResolvedRootParameters } from '@module/common/types';
import type { SessionModule } from '@module/session/definition';

import { LocalStorage } from '../common/modules/LocalStorage';
import { dummySessionMeta } from '../frankie-client/DummyFrankieApiClient';
import { SdkMode } from '../index.js';

import { StorageKeys } from './constants';
import { SessionContext } from './SessionContext';

export default defineModule<SessionModule>('session', (...args) => {
  const [oneSdkParameters] = args;
  let sessionContext: SessionContext;
  const isDummy = oneSdkParameters.mode?.modeName === SdkMode.DUMMY;

  // Decide which token to use and create a session context
  if (isDummy) sessionContext = mkDummySessionContext(...args);
  else {
    sessionContext = resolveToken(...args);
  }

  // If current session was just taken from storage, or is in dummy mode, don't re-store it
  // This might be here to cover a scenario where a Module is attempting to persist a session, not during the OneSDK initialisation
  // TODO: Verify the above. We might want to add a method "persist" to the SessionContext so modules can choose to persist a session, instead of relying on the global "persist" option
  // ! WARNING: When persist is explicitly false we might not want to allow modules to persist a session though
  // ? Why does dummy mode not store the token?
  if (
    oneSdkParameters.session.persist &&
    !isDummy &&
    !sessionContext.isTakenFromStorage()
  ) {
    const storage = new LocalStorage();
    storage.store(StorageKeys.TOKEN, sessionContext.token);
  }
  return sessionContext;
});

export function mkDummySessionContext(
  ...args: ModuleParameters<SessionModule>
): SessionContext {
  const [oneSdkParameters] = args;
  // Even in dummy mode, if token is provided resolve session context based on token
  if (oneSdkParameters.session.token) return resolveToken(...args);
  // If token is not provided, use the default dummySessionMeta to create a new session context
  return new SessionContext(dummySessionMeta, {
    takenFromStorage: false,
    appReference: oneSdkParameters.session.appReference,
  });
}
export function resolveToken(
  ...args: ModuleParameters<SessionModule>
): SessionContext {
  const [oneSdkParameters, options] = args;
  const { globalEventHub } = options;
  const { session } = oneSdkParameters;
  const providedToken = session.token ?? null;
  const persistOption = session.persist === true;
  const appReference = session.appReference ?? null;

  if (!providedToken) throw new Error('No token provided to session.token');

  const getStoredToken = () => storage.retrieve<string>(StorageKeys.TOKEN);
  const deleteStoredToken = () => storage.delete(StorageKeys.TOKEN);

  const mkProvidedSessionContext = (storeToken: boolean) => {
    if (storeToken) storage.store(StorageKeys.TOKEN, providedToken);
    return new SessionContext(providedToken, {
      takenFromStorage: false,
      appReference,
    });
  };
  const mkStoredSessionContext = (existingToken: string) => {
    return new SessionContext(existingToken, {
      takenFromStorage: deleteStoredToken,
      appReference,
    });
  };

  const storage = new LocalStorage();
  // persist == false
  // -- simply create a new session context with the provided token and don't store it
  if (!persistOption) return mkProvidedSessionContext(false);
  const storedToken = getStoredToken();
  // persist == true AND
  // stored token NOT EXISTS
  // -- store provided token and use it to create a new session context
  if (persistOption && !storedToken) return mkProvidedSessionContext(true);
  // persist == true AND
  // stored token EXISTS
  // -- decode stored token
  // -- validate it
  // -- if valid, use it to create a new session context
  // -- if invalid, use provided token to create a new session context
  try {
    // A token may be invalid here, during parsing
    const storedSessionContext = mkStoredSessionContext(storedToken);
    // Or here, due to
    // 1) an expired "exp" value, but only if not in dummy mode, or
    // 2) a mismatched entity id or reference, even if in dummy mode
    assertStoredSessionIsValid(
      storedSessionContext,
      mkProvidedSessionContext(false),
      oneSdkParameters,
    );
    // If it's valid, use stored token
    // ! ATTENTION: At this stage we do not check if token is valid for BFF, only if the meta info is valid (see comment above)
    return storedSessionContext;
  } catch (e) {
    globalEventHub.emit('warning', {
      message: "Stored token is invalid. Using provided token. See 'payload'.",
      payload: e,
    });
    // If invalid, use new provided token
    return mkProvidedSessionContext(true);
  }
}

function assertStoredSessionIsValid(
  storedSession: SessionContext,
  providedSession: SessionContext,
  oneSdkParameters: ResolvedRootParameters,
) {
  const isDummy = oneSdkParameters.mode.is(SdkMode.DUMMY);
  // First check if stored token even is valid, but only if not in dummy mode
  if (!isDummy && !storedSession.isValid()) throw new Error('Expired token');
  // If it's valid, check if it's compatible with the provided token
  // First check if either token uses entityId as identifier. If either do, then check if they match
  const usesEntityId = storedSession.entityId || providedSession.entityId;
  const hasSameEntityId = storedSession.entityId === providedSession.entityId;
  if (usesEntityId && !hasSameEntityId)
    throw new Error('Stored token does not match provided entity id');
  // Then do the same for reference
  const usesReference = storedSession.reference || providedSession.reference;
  const hasSameReference =
    storedSession.reference === providedSession.reference;
  if (usesReference && !hasSameReference)
    throw new Error('Stored token does not match provided reference');
}
export * from './definition';
