import { Scan, TScan } from "./Document";
import { ICloneable, Nullable } from "./general";
import { IChangeStamp } from "./IChangeStamp";

// TODO Values to be decided
export enum DocumentType {
  DRIVERS_LICENCE = "DRIVERS_LICENCE",
  PASSPORT = "PASSPORT",
  VISA = "VISA",
  IMMIGRATION = "IMMIGRATION",
  NATIONAL_ID = "NATIONAL_ID",
  NATIONAL_HEALTH_ID = "NATIONAL_HEALTH_ID",
  CONCESSION = "CONCESSION",
  HEALTH_CONCESSION = "HEALTH_CONCESSION",
  PENSION = "PENSION",
  MILITARY_ID = "MILITARY_ID",
  BIRTH_CERT = "BIRTH_CERT",
  CITIZENSHIP = "CITIZENSHIP",
  MARRIAGE_CERT = "MARRIAGE_CERT",
  DEATH_CERT = "DEATH_CERT",
  NAME_CHANGE = "NAME_CHANGE",
  UTILITY_BILL = "UTILITY_BILL",
  BANK_STATEMENT = "BANK_STATEMENT",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  INTENT_PROOF = "INTENT_PROOF",
  SELF_IMAGE = "SELF_IMAGE",
  EMAIL_ADDRESS = "EMAIL_ADDRESS",
  MSISDN = "MSISDN",
  DEVICE = "DEVICE",
  VEHICLE_REGISTRATION = "VEHICLE_REGISTRATION",
  PROOF_OF_ADDRESS = "PROOF_OF_ADDRESS",
  EXTERNAL_ADMIN = "EXTERNAL_ADMIN",
  CHARGES = "CHARGES",
  PRE_ASIC = "PRE_ASIC",
  ANNUAL_RETURN = "ANNUAL_RETURN",
  REPORT = "REPORT",
  TRUST_DEED = "TRUST_DEED",
  PARTNERSHIP_AGREEMENT = "PARTNERSHIP_AGREEMENT",
  ADMIN_CHANGE = "ADMIN_CHANGE",
  COMPANY_REPORT = "COMPANY_REPORT",
  CHECK_RESULTS = "CHECK_RESULTS",
  OTHER = "OTHER",
  RENTAL_AGREEMENT = "RENTAL_AGREEMENT",
  TAX_STATEMENT = "TAX_STATEMENT",
  HOUSE_REGISTRATION = "HOUSE_REGISTRATION",
  YELLOW_HOUSE_REGISTRATION = "YELLOW_HOUSE_REGISTRATION",
  WORK_PERMIT = "WORK_PERMIT",
  EMPLOYMENT_CERTIFICATE = "EMPLOYMENT_CERTIFICATE",
  NOTARY_PUBLIC_ID = "NOTARY_PUBLIC_ID",
}

export enum DocumentStatus {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  NEEDS_REVIEW = "NEEDS_REVIEW",
}

export type SupportingDocumentReference = {
  uuid: string;
  type: string;
};

export const ALLOWED_MIME_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "image/tiff",
  "image/bmp",
  "application/pdf",
];

export const ALLOWED_MIME_TYPES_STRING = ALLOWED_MIME_TYPES.join(", ");

export interface ISupportingDocument {
  id?: Nullable<string>;
  type: DocumentType;
  label: string;
  file: Nullable<Scan>;
  status?: Nullable<DocumentStatus>;
  modified?: Nullable<IChangeStamp>;
  reviewed?: Nullable<IChangeStamp>;
  refer?: Nullable<SupportingDocumentReference>;
  smartUiDocOrder?: Nullable<number>;
}
export interface ISupportingDocumentPayload {
  id?: Nullable<string>;
  type: DocumentType;
  label: string;
  file: Nullable<TScan>;
  status?: Nullable<DocumentStatus>;
  modified?: Nullable<IChangeStamp>;
  reviewed?: Nullable<IChangeStamp>;
  refer?: Nullable<SupportingDocumentReference>;
  smartUiDocOrder?: Nullable<number>;
}

export class SupportingDocument implements ISupportingDocument, ICloneable<SupportingDocument> {
  id: Nullable<string>;
  type: DocumentType = DocumentType.OTHER;
  label: string;
  file: Nullable<Scan>;
  status: Nullable<DocumentStatus>;
  modified: Nullable<IChangeStamp>;
  reviewed: Nullable<IChangeStamp>;
  refer: Nullable<SupportingDocumentReference>;
  smartUiDocOrder: Nullable<number>;

  constructor(payload: ISupportingDocument) {
    this.id = payload.id ?? null;
    this.type = payload.type;
    this.label = payload.label;
    this.file = payload.file ?? null;
    this.status = payload.status ?? null;
    this.modified = payload.modified ?? null;
    this.reviewed = payload.reviewed ?? null;
    this.refer = payload.refer ?? null;
    this.smartUiDocOrder = payload.smartUiDocOrder ?? null;
  }
  clone(): SupportingDocument {
    return SupportingDocument.fromJSON(this.toJSON());
  }

  toJSON(): ISupportingDocument {
    const file = this.file ? this.file.toJSON() : null;
    return {
      ...this,
      file,
    };
  }

  static fromJSON(payload: ISupportingDocumentPayload): SupportingDocument {
    const file = payload.file ? Scan.fromJSON(payload.file) : null;
    return new SupportingDocument({
      ...payload,
      file,
    });
  }
}
