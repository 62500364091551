import { OneSDKError } from '@module/common/errors/OneSDKError.class';
import type { EventHub } from '@module/common/modules/eventHub';
import type { Document } from '@module/common/shared/models/Document';
import { promisifyForm } from '@module/common/vendors/form/promisifyForm';
import phrases from '@module/form/vendors/legacy/i18n.json';
import {
  updateIndividualModuleState,
} from '@module/idv/vendors/common/iframeUtils/updateIndividualModuleState';
import type { OneSdkContext } from '@module/types';

type IDVResults = { document: Document; entityId: string };
type Dependencies = {
  oneSdkInstance: OneSdkContext;
  localEventHub: EventHub<{
    loading: [boolean, { message: string }];
    detection_complete: [];
    detection_failed: [OneSDKError];
    results: [IDVResults];
    ready: [{ domElement: HTMLElement }];
  }>;
  mountElement: HTMLElement;
  /** When this option is true, the Form will be rendered before the 'results' event. */
  attachForm: boolean;
};

/**
 * Use currying to create a callback function that will be called when the vendor emits "results" event.
 * The callback may or may not include the Form for reviewing the results,
 * based on the value of the "attachForm" parameter.
 *
 * Attention: The caller "loading" state should be true when calling this method.
 * A "loading" false event will be emitted when the initial actions are completed.
 */
export function useFormToReviewResults(deps: Dependencies) {
  const { localEventHub, oneSdkInstance, mountElement, attachForm } = deps;

  return async function callback(results: IDVResults) {
    try {
      let { document } = results;
      await updateIndividualModuleState(deps);
      // If Form is included, wait for it to emit results and use the resulting
      // document instead of the document provided by idv "results"
      if (attachForm) {
        const formContext = oneSdkInstance.component('form', {
          welcomeScreen: false,
          saveOnly: true,
          phrases,
          injectedCss: `
          .ff-outcome-message {
            display: none !important;
          }
          `,
        });
        // This event will be triggered once the Form screen is ready to be
        // displayed
        formContext.on('ready', ({ domElement }) => {
          localEventHub.emit('loading', false, { message: `Form loaded for user review` });
          localEventHub.emit('ready', { domElement });
        });
        // Wait for the user to complete the Form and take the resulting
        // document from it
        document = await promisifyForm(formContext, {
          documentId: document.documentId,
          mountElement,
        });

        // Currently all state passed from Form to one sdk and vice versa is
        // done through API requests, which is highly inefficient
        // For that reason we need to retrigger individual module's search/update
        // TODO: Remove the need to keep updating individual module by
        //  sharing state with the Form module through the event hub
        localEventHub.emit('loading', true, { message: 'Updating individual state' });
        await updateIndividualModuleState(deps);
        // Emitting it here to make it consistent with vendors that have review screens
        // In such cases the review screen is displayed before "detection_complete" is emitted
        localEventHub.emit('detection_complete');
        mountElement.innerHTML = '';
      }
      localEventHub.emit('loading', false, { message: 'Vendor capture was completed including user review' });
      localEventHub.emit('results', {
        ...results,
        document,
      });
    } catch (e) {
      localEventHub.emit('detection_failed', new OneSDKError(`Failed during data review in form`, e));
      localEventHub.emit('loading', false, { message: `Failed during data review in form` });
    }
  };
}
