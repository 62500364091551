import { UAParser } from 'ua-parser-js';

import type { EventHub } from '@module/common';
import { mkEventHub } from '@module/common';
import type { TEventPayload } from '@module/common/shared/models/api';
import { TelemetryEvent } from '@module/common/shared/models/TelemetryEvent';
import type {
  ResolvedRootParameters,
  SharedDependencies,
} from '@module/common/types';
import { getInitialiser } from '@module/config';
import type { FrankieApiClient } from '@module/frankie-client';
import { mkFrankieClient } from '@module/frankie-client';
import { TelemetryEventsClient } from '@module/frankie-client/clients/TelemetryEventsClient';
import packageMeta from '@package-meta';

import { OneSDKError } from '../../common/errors/OneSDKError.class';

import { includeTelemetry } from './configurationParser';

import type { GlobalEvents } from '../types';

const oneSdkVersion = packageMeta.version;

export async function mkSharedDependencies(
  oneSdkParameters: ResolvedRootParameters,
): Promise<SharedDependencies> {
  // Create global event hub to be passed to all OneSdk modules
  const { mode } = oneSdkParameters;

  const globalEventHub = mkEventHub<GlobalEvents>();
  // ! WARNING: Events emitted here are not visible to the host application
  // TODO: Create a LazyEventHub object that allows event emission to be delayed until the first listener is added and attach it to the global event hub
  // Instead of emitting the event, it simply stores the calls and emit it once requested
  // This can be used HERE, by the session module to emit warnings and telemetry events
  // It can also be used by the function `parseConfiguration`, instead of returning an array of "warnings"
  const sessionContext = getInitialiser('session')(oneSdkParameters, {
    globalEventHub,
  });
  // Initialise a Frankie Client object to connect with our backend APIs
  const frankieClient = await mkFrankieClient({
    mode,
    session: sessionContext,
  });
  // Use the already authenticated frankie client to register the "telemetry" events
  // This will cause any emitted "telemetry" events anywhere in the onesdk to be registered to our backend
  try {
    if (includeTelemetry(oneSdkParameters))
      registerTelemetryEvents(globalEventHub, frankieClient);
  } catch (e) {
    // ! ATTENTION: The reason for using console.warn instead of an event "warning" is mentioned above.
    // eslint-disable-next-line no-console
    console.warn(
      `Non critical error detected: ${e.message}. Telemetry events might not be emitted for analytics.`,
    );
  }
  return { globalEventHub, frankieClient, session: sessionContext };
}

export function registerTelemetryEvents(
  globalEventHub: EventHub<GlobalEvents>,
  frankieClient: FrankieApiClient,
) {
  const session = frankieClient.session;
  const screen = window.screen;
  const DESKTOP_MINIMUM_WIDTH = 961;
  const getScreenOrientation = (): OrientationType | 'unknown' => {
    if (screen.orientation) {
      return screen.orientation?.type ?? 'unknown';
    }
    // iOS devices don't support screen.orientation,
    // so we need to use window.orientation
    const angle = window.orientation ?? undefined;

    switch (angle) {
      case 0:
        return 'portrait-primary';
      case 180:
        return 'portrait-secondary';
      case 90:
        return 'landscape-primary';
      case -90:
        return 'landscape-secondary';
      default:
        return 'unknown';
    }
  };
  const screenInformation = !screen
    ? 'no screen information'
    : {
        height: screen.height,
        width: screen.width,
        availableHeight: screen.availHeight,
        availableWidth: screen.availWidth,
        colorDepth: screen.colorDepth,
        orientation: getScreenOrientation(),
        pixelDepth: screen.pixelDepth,
      };
  const userAgentDetails = new UAParser().getResult();
  const norm = <T extends string>(v: string) => v?.toLowerCase() as T;
  const resolveDeviceType = () => {
    // Desktop device type can't be inferred from UA,
    // so whenever it's undefined infer it from the screen size
    const fromUserAgent = userAgentDetails.device.type;
    const fromScreenSize =
      typeof screenInformation === 'string'
        ? 'undefined'
        : screenInformation.width >= DESKTOP_MINIMUM_WIDTH
          ? 'desktop'
          : 'mobile';
    return norm<'desktop' | 'tablet' | 'mobile'>(
      fromUserAgent ?? fromScreenSize,
    );
  };
  // Assemble common telemetry event payload
  const commonEventPayload = (): Omit<TEventPayload, 'data'> => ({
    entityId: session.entityId ?? 'none',
    customerReference: session.reference ?? 'none',
    channel: ['one-sdk', frankieClient.session.appReference]
      .filter(Boolean)
      .join('/'),
    customerId: session.customerID,
    customerChildId: session.customerChildID ?? 'none',
    sessionId: session.sessionId,
    version: oneSdkVersion,
    environment: session.environment,
    browser: {
      userAgent: userAgentDetails.ua,
      language: norm(navigator.language),
      name: norm(userAgentDetails.browser.name),
      version: norm(userAgentDetails.browser.version),
    },

    device: {
      type: resolveDeviceType(),
      screen: screenInformation as TEventPayload['device']['screen'],
      model: norm(userAgentDetails.device.model),
      osName: norm(userAgentDetails.os.name),
      osVersion: norm(userAgentDetails.os.version),
      vendor: norm(userAgentDetails.device.vendor),
      cpu: userAgentDetails.cpu,
      engine: userAgentDetails.engine,
    },
  });
  const serialiseBestEffort = (error) => {
    let payload;
    if (error instanceof Error) {
      payload = {
        message: error.message,
        stack: error.stack,
      };
    } else {
      payload = JSON.parse(
        JSON.stringify(error, Object.getOwnPropertyNames(error)),
      );
    }
    if (error instanceof OneSDKError) {
      payload.payload = error.payload;
    }

    return payload;
  };
  const telemetryClient = new TelemetryEventsClient(frankieClient);
  // On each 'telemetry' event, submit event data to the telemetry/events endpoint
  // Log each submission success or failure as a new event that may or may not be used by the user
  globalEventHub.on('telemetry', (eventContents) => {
    try {
      const eventName =
        typeof eventContents === 'string'
          ? eventContents
          : eventContents.eventName;
      const data =
        typeof eventContents === 'string'
          ? {}
          : { ...eventContents.data } ?? {};
      const error =
        typeof eventContents === 'string' ? null : eventContents.error;

      if (error) {
        // If an Error object is passed to the telemetry event, extract only the useful and serialisable information,
        // If the error isn't an instance of Error, attempt to serialise it
        data.error = serialiseBestEffort(error);
      }

      const event = new TelemetryEvent(eventName, {
        ...commonEventPayload(),
        data,
      });
      // Submit event asynchronously. The timing here doesn't matter as this is not a functionality customers
      // will be relying on, or expecting to be completed. We submit, define a successful and a failed callback
      // to simply inform or warn about the result, but won't emit "error" in case this fails.
      telemetryClient
        .dispatch(event)
        .then(() =>
          globalEventHub.emit('info', {
            message: "Event 'telemetry' submitted successfully",
            payload: { eventName, data },
          }),
        )
        .catch((error) => {
          globalEventHub.emit('warning', {
            message:
              "There was a problem submitting event 'telemetry'. This doesn't impact the proper functioning of the OneSDK.",
            payload: { eventName, data, error },
          });
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Telemetry event failed. ${e.message}`);
    }
  });
}
