import * as utils from '@module/common';
import { ENVIRONMENTS, OneSDKError } from '@module/common';
import { FrankieApiClient } from '@module/frankie-client';
import { SdkModes } from '@module/sdk/types';
// TODO: Take version and commit hash from environment variables defined by the pipeline
// TODO: Don't use imports from
import packageMeta from '@package-meta';

import { oneSdk } from './sdk';
// Export all types defined in ./types.ts
export * from './exports';
export * from './types';
export { OneSDK };
/**
 * Exported OneSdk object
 * Either named as OneSDK or as a default export, which when loaded via CDN
 * The UMD build will attach the default export to the global window object as [window.]OneSdk
 *
 * The default export of the OneSDK, also exposed as a named variable `OneSDK`.
 * It is an asynchronous function that returns a Promise of the OneSdkContext,
 * which is essentially an instance of the OneSDK.
 * (options: OneSdkRootParameters) => Promise<OneSdkContext>
 *
 * This default export also contains utilities attached to it, such as the `modes` enum.
 * @param {OneSdkRootParameters} options
 * @returns {Promise<OneSdkContext>} Promise of an Instance of OneSDK
 */
const OneSDK = Object.assign(oneSdk.initialise, {
  FrankieApiClient,
  utils,
  modes: SdkModes,
  environments: ENVIRONMENTS,
  version: packageMeta.version,
  wildcardEventLogger,
});
export default OneSDK;

export function wildcardEventLogger(
  ev: { eventName: string; arguments: unknown[] },
  stackTrace?: string[],
) {
  if (ev.eventName === 'telemetry') return;

  console.group(`Event '${ev.eventName}'`);
  // eslint-disable-next-line no-console
  ev.arguments.forEach((arg) => console.log(serialiseArgument(arg)));
  // eslint-disable-next-line no-console
  if (stackTrace) console.dir(stackTrace);
  console.groupEnd();
}
const serialiseArgument = (arg: unknown) =>
  arg instanceof OneSDKError
    ? { message: arg.message, payload: arg.payload }
    : arg instanceof Error
      ? { message: arg.message }
      : arg instanceof Function || typeof arg === 'function'
        ? { functionName: arg.name ?? 'no name' }
        : arg;

declare global {
  interface Window {
    OneSdk: typeof import('.').default;
  }
}
