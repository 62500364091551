import merge from 'lodash.merge';

import { mkEventHub, mkVendorLoader } from '@module/common';
import { defineModule } from '@module/common/modules/defineModule';
import type { FormEvents } from '@module/form/events';
import { FormTelemetryDict } from '@module/form/events';

import { validateConfiguration } from './validateConfiguration';

import type { FormModule } from './definition';

export default defineModule<FormModule>('form', (globalState, options = {}) => {
  validateConfiguration(globalState);

  let htmlElement: HTMLElement;

  const {
    globalEventHub,
    recipe: {
      form: { provider },
    },
    localEventHub,
  } = globalState;

  // initialise root config if it's missing
  provider.config = merge(provider.config ?? {}, options);

  const vendorName = provider?.name ?? 'legacy';
  const loadForm = mkVendorLoader({
    sharedConfiguration: globalState,
    vendorName,
    vendorLoader: {
      legacy: () =>
        import(
          /* webpackChunkName: 'form-legacy' */ './vendors/legacy/index.js'
        ),
      formio: () =>
        import(
          /* webpackChunkName: 'form-formio' */ './vendors/formio/index.js'
        ),
      react: () =>
        import(/* webpackChunkName: 'form-react' */ './vendors/react/index.js'),
    },
  });
  const privateEventHub = mkEventHub<FormEvents>();
  const mount: FormModule['moduleContext']['mount'] = (mountElement) => {
    htmlElement =
      typeof mountElement === 'string'
        ? document.querySelector(mountElement)!
        : mountElement;
    return loadForm({
      vendorWrapperOptions: () => {
        return {
          eventHub: privateEventHub,
          mountElement: htmlElement,
        };
      },
      onSuccess: (_, { vendorName }) => {
        globalEventHub.emit('telemetry', {
          eventName: 'FORM:MOUNT',
          data: { vendor: vendorName },
        });
      },
      onError: (error, { vendorName }) => {
        globalEventHub.emit('telemetry', {
          eventName: 'FORM:MOUNT:ERROR',
          data: { vendor: vendorName },
          error,
        });
      },
    });
  };

  const unmount = () => {
    if (htmlElement && htmlElement.innerHTML) {
      htmlElement.innerHTML = '';
    }
  };

  // Event mappings for telemetry
  for (const [eventKey, telemetryName] of Object.entries(FormTelemetryDict)) {
    localEventHub.on(eventKey as keyof FormEvents, () =>
      globalEventHub.emit('telemetry', {
        eventName: telemetryName,
      }),
    );
  }

  return {
    mount,
    unmount,
    provider: vendorName,
  };
});
