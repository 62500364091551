import type { Applicant } from '@module/common/shared/models/Applicant';
import type { Accessors } from '@module/types';

import type { IndividualModule } from '../definition';

type SetExtraData = IndividualModule['moduleContext']['setExtraData'];

type MkSetExtraData = {
  applicant$: Accessors<Applicant>;
};

export function mkSetExtraData(deps: MkSetExtraData): SetExtraData {
  const { applicant$ } = deps;

  return (data: object) => {
    const applicant = applicant$.getValue();
    applicant.extraData = {
      ...data,
    };
    applicant$.setValue(applicant);
  };
}

export function mkGetExtraData() {
  // TODO
}
