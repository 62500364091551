import merge from 'lodash.merge';
import { v4 as uuid } from 'uuid';

import type { Address } from '@module/common/shared/models/Address';
import type { Applicant } from '@module/common/shared/models/Applicant';
import type {
  Document,
  IDocumentPayload,
} from '@module/common/shared/models/Document';
import type { DeepPartial } from '@module/common/shared/models/general';

import { OCRStatus } from '../../clients/OCRClient';
import { URL_PARAM } from '../../DummyFrankieApiClient';
import { resolveScanData } from '../individual/resolveDocumentData';

import type { OCRDocumentType } from '../../clients/OCRClient';
import type { DummyFrankieApiClient } from '../../DummyFrankieApiClient';
import type { IDVFlowOptions } from '../idvAndBiometrics/idvSubmission';
import type { MockOptions as _MockOptions } from '../options/MockOptions.type';
import type { PartialDeep } from 'type-fest';

const scanUuidFront = uuid();
const scanUuidBack = uuid();
export { OCRStatus as Status };
export type OCRDocumentOptions = {
  status: OCRStatus;
  ocrDocument: PartialDeep<Document>;
};
export type Options = {
  detectedType: OCRDocumentType;
  updateResponsePayloads: OCRDocumentOptions[];
  postResponsePayload: OCRDocumentOptions;
};
type MockOptions = _MockOptions & { ocrRequests: Options };

export function submitOCR(
  client: DummyFrankieApiClient,
  options: MockOptions,
): DummyFrankieApiClient {
  createOCRDocument(client, options);
  updateOCRDocument(client, options);

  return client;
}

export function createOCRDocument(
  client: DummyFrankieApiClient,
  options: MockOptions,
): DummyFrankieApiClient {
  client.stubResponse(
    {
      url: new RegExp(`data/v2/idvCheck/${URL_PARAM}/ocr`),
      method: 'post',
    },
    () => {
      const data = mkOCRResultPayload(
        options.ocrRequests.postResponsePayload,
        options,
      );
      return {
        delay: 800,
        title: `OCR New document`,
        data,
        status: 200,
      };
    },
  );
  return client;
}

export function updateOCRDocument(
  client: DummyFrankieApiClient,
  options: MockOptions,
): DummyFrankieApiClient {
  client.stubResponse(
    {
      url: new RegExp(`data/v2/idvCheck/${URL_PARAM}/ocr`),
      method: 'put',
    },
    (config) => {
      const currentRequestPayload =
        options.ocrRequests.updateResponsePayloads.shift();
      // If request data is FormData, which will most likely include a file, take the document id from FormData object
      // If it's a plain object, take it from the object
      // If it's neither and/or document isn't present, function mkFullPayload will generate a new one
      // Also, *if  not submitting image file, this is an OCR rerun*
      const documentId: string | null =
        config.data instanceof FormData
          ? config.data.get('documentId')
          : config.data.documentId;
      if (documentId) currentRequestPayload.ocrDocument.documentId = documentId;
      const data = mkOCRResultPayload(currentRequestPayload, options);
      return {
        title: `Update document for OCR`,
        delay: 800,
        data,
        status: 200,
      };
    },
  );

  return client;
}

/**
 * idvFlow options describe what happens in each request to the ocr endpoints. This function will determine the result of each request.
 * Creates a full payload based on the current request, to go into the data field of the axios response.
 * It does a deep merge of the default payload with the provided results.
 * @param requestOptions the options describing the results for the current request
 * @param allMockOptions a higher level view on all the options, beyond this current request
 * @returns a complete payload for the current request, to go into the data field of the axios response
 */
export const mkOCRResultPayload = (
  requestOptions: OCRDocumentOptions,
  allMockOptions: {
    ocrRequests: { detectedType: OCRDocumentType };
    idvFlow?: IDVFlowOptions;
  },
): { status: OCRStatus; ocrDocument: IDocumentPayload } => {
  // In case idvFlow is provided, it takes precedence over options from ocrFlow (which is provided progresively in requestOptions)
  const idvFlow: IDVFlowOptions | null = allMockOptions.idvFlow ?? null;
  // Individual extract can only come from idvFlow and will simply use defaults if not present
  /* Individual details */
  const extractedIndividual: DeepPartial<Applicant> | null =
    idvFlow?.capturedIndividualDetails ?? null;
  const dateOfBirth = extractedIndividual?.dateOfBirth ?? '1990-01-01';
  const firstName = extractedIndividual?.name?.givenName ?? 'PETER';
  const lastName = extractedIndividual?.name?.familyName ?? 'TESTTHIRTEEN';
  /* Address extract */
  const address: DeepPartial<Address> | null =
    extractedIndividual?.addresses?.[0] ?? null;
  const streetAddressParts = [
    address?.streetNumber,
    address?.streetName,
    address?.streetType,
  ].filter(Boolean);
  const hasStreetAddress = streetAddressParts.length > 0;
  const street = hasStreetAddress
    ? streetAddressParts.join(' ')
    : '80 Collins Street';
  const state = address?.state ?? 'VIC';
  const town = address?.town ?? address?.suburb ?? 'Melbourne';
  const postcode = address?.postalCode ?? '3000';
  /* Document extract */
  // status may be provided as IDVStatus and should still be used as if it's an OCRStatus
  const status: OCRStatus =
    (idvFlow?.result as unknown as OCRStatus) ?? requestOptions.status;
  const ocrDocument: DeepPartial<Document> =
    idvFlow?.capturedDocumentDetails ?? requestOptions.ocrDocument ?? {};
  const idType =
    ocrDocument.idType ??
    allMockOptions?.ocrRequests?.detectedType ??
    'PASSPORT';
  const issuingCountry = ocrDocument.country ?? 'AUS';
  // Scan objects
  const scansOptions = ocrDocument?.scans ?? [];
  const scans = scansOptions.map(resolveScanData).map((scan) => {
    // Mocked Scan objects are defined by the side only. Everything else is predetermined
    if (scan.side === 'F') scan.scanId = scanUuidFront;
    if (scan.side === 'B') scan.scanId = scanUuidBack;
    return scan;
  });
  const idNumber = ocrDocument?.idNumber ?? 'P77864526';

  return {
    status,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ocrDocument: merge(
      {
        country: 'AUS',
        dateOfBirth: null,
        documentId: uuid(),
        extraData: {},
        gender: null,
        idExpiry: '0001-01-01',
        idNumber,
        idSubType: null,
        idType,
        ocrResult: {
          status,
          runDate: new Date().toISOString(),
          documentTypeInternal: idType,
          dateOfExpiry: '2031-05-28',
          dateOfIssue: '2021-05-28',
          documentType: idType,
          documentNumber: idNumber,
          dateOfBirth,
          nationality: issuingCountry,
          issuingState: null,
          issuingCountry,
          state,
          postcode,
          town,
          street,
          firstName,
          lastName,
          mismatch: ['firstName', 'lastName', 'documentNumber', 'dateOfBirth'],
        },
        region: state,
        scans: [],
        validation: {
          electronic: {
            isValid: true,
            validationReport: null,
          },
          manual: {
            isValid: null,
          },
        },
        verified: {
          electronic: null,
          manual: null,
        },
      },
      ocrDocument,
      {
        idType,
        scans,
        docScan: scans,
      },
    ) as IDocumentPayload,
  };
};
