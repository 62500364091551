import { DateTime } from 'luxon';

import type { Events, InternalState } from '@module/business';
import type { EventHub, ReactiveStore } from '@module/common';
import { Scan } from '@module/common/shared/models/Document';
import type { IChangeStamp } from '@module/common/shared/models/IChangeStamp';
import { ChannelString } from '@module/common/shared/models/IChangeStamp';
import type { DocumentType } from '@module/common/shared/models/SupportingDocument';
import { SupportingDocument } from '@module/common/shared/models/SupportingDocument';
import type { SupportingDocumentsClient } from '@module/frankie-client/clients/SupportingDocumentsClient';

type MkUploadDeps = {
  client: SupportingDocumentsClient;
  eventHub: EventHub<Events>;
  state$: ReactiveStore<InternalState>;
};
export const mkUpload =
  (deps: MkUploadDeps) => async (file: File, type: DocumentType) => {
    const { client, state$, eventHub } = deps;

    const isLoading$ = state$.getRootAccessors('isLoading');

    isLoading$.setValue(true);

    const { id } = await client.upload(file, eventHub);

    const activityDetails: IChangeStamp = {
      by: ChannelString.ONESDK,
      channel: ChannelString.ONESDK,
      timestamp: DateTime.utc().toISO(),
      userId: null,
    };

    await client.create(
      new SupportingDocument({
        type,
        label: type,
        file: new Scan({
          scanId: null,
          fileUploadUuid: id,
          file: null,
          mimeType: file.type,
          side: 'F',
          scanCreated: DateTime.utc().toISO(),
          scanName: file.name,
        }),
        modified: activityDetails,
        reviewed: activityDetails,
      }),
    );
    isLoading$.setValue(false);
    return;
  };
