import { v4 as uuid } from 'uuid';

import { merge } from '@module/common';
import type {
  AnyModuleInitialiseFunction,
  FactoryParameters,
  InjectedState,
  InstanceObject,
} from '@module/common/types';
import type { Modules } from '@module/config';
import { getInitialiser } from '@module/config';
import type { Dictionary } from '@types';

type FactoryFunctions = Modules['factoryFunctions'];
type ModuleOptions = Modules['moduleOptions'];
type Instances = Modules['instances'];
type InstantiableCategory = 'component' | 'flow';
type ModuleName = Modules['moduleName'];
/**
 * The module instantiator takes a partial InjectedState object, missing only the "moduleMeta" object (module name and instance name)
 * Based on the options provided to the instantiator, along with the module name, it will inject moduleMeta to the existing InjectedState and use that
 * to initialise the module.
 */
export type InjectedDependencies = Omit<InjectedState, 'moduleMeta'>;
/**
 * This factory will generate another function which will
 * 1) load a module by module name, defined in the dictionary in Configuration.ts;
 * 2) initialise it, which generates a context object, aka an "instance" of the module
 * 3) tag that instance with an "instance name"
 *
 * @param globalState All options provided to OneSdk merged with resulting globalState object
 * @returns A function to load OneSdk modules
 */
export const mkModuleInstantiator = <Category extends InstantiableCategory>(
  globalState: InjectedDependencies,
): FactoryFunctions[Category] => {
  const {
    retrieveInstance,
    storeInstance,
    instanceStore,
  } = mkInstanceDictionary();
  /**
   * TODO: description
   * @param parameters Modulename: string and options: object
   * @returns Module instance
   */
  const moduleInstantiator = <Name extends Modules['moduleName']>(
    ...parameters: FactoryParameters<Name, ModuleOptions[Name]>
  ): Instances[Name] => {
    type GenericOptions = { instanceName: string } & Dictionary<unknown>;

    const [which, providedOptions] = parameters as unknown as [Name, GenericOptions];
    const instanceName = providedOptions?.instanceName ?? uuid();
    // Use instance name to simply load an existing instance or to generate a new one and store it under that name
    // instance name defaults to "default".
    // Change: instanceName used to default to the module name itself.
    const { instanceName: _, ...moduleOptions } = providedOptions ?? {};
    const resolvedOptions: GenericOptions = { instanceName, ...providedOptions };

    // if instance already exists, return it
    const instance = retrieveInstance(which, instanceName);
    if (instance) return instance as Instances[Name];
    else {
      // Otherwise,
      // 1) resolve module parameters: baseModuleOptions and moduleOptions
      const parameters = mkModuleParameters(which, instanceName, globalState, moduleOptions);
      globalState.globalEventHub.emit('resolved_module_config', ...parameters);

      const module = getInitialiser(which) as AnyModuleInitialiseFunction;

      try {
        // 2) Initialise module, resulting in the module context for that instance (aka a component)
        const moduleContext = module(...parameters);
        const instance = injectInstanceName(moduleContext, instanceName);
        storeInstance(which, instanceName, instance);

        // 3) Emit INIT event to telemetry immediately and map any future "error" events for this component, if applicable
        // !ATTENTION! the component's resolved options will be stored in the database,
        // !which means that any sensitive information (PII) either shouldn't be provided here, or should be removed from the options object before emitting the event
        // !This also applies to event COMPONENT:INIT:ERROR
        globalState.globalEventHub.emit('telemetry', {
          eventName: `COMPONENT:INIT`,
          data: { component: which, options: resolvedOptions },
        });
        // Since all event listeners support the "error" event, listen for any "error" events and submit them to "telemetry"
        instance.on('error', (errorEvent) =>
          globalState.globalEventHub.emit('telemetry', {
            eventName: `COMPONENT:ERROR`,
            data: { component: which },
            error: errorEvent,
          }),
        );

        return instance as Instances[Name];
      } catch (error) {
        globalState.globalEventHub.emit('telemetry', {
          eventName: `COMPONENT:INIT:ERROR`,
          data: { component: which, options: resolvedOptions },
          error,
        });
        throw error;
      }
    }
  };
  // the "defineProperty" below exposes the instances object and allows for easier testing
  return Object.defineProperty(moduleInstantiator, 'instances', {
    value: instanceStore,
    enumerable: false,
    writable: false,
  }) as Modules['factoryFunctions'][Category];
};
const injectInstanceName = <ModuleContext extends object>(
  moduleContext: ModuleContext,
  instanceName: string,
) => {
  return Object.defineProperty(moduleContext, 'instanceName', {
    value: instanceName,
    enumerable: true,
    writable: false,
  }) as InstanceObject<ModuleContext>;
};

function mkModuleParameters(
  moduleName: ModuleName,
  instanceName: string,
  globalState: InjectedDependencies,
  moduleOptions: Dictionary<unknown> | Dictionary<never>,
): [InjectedState, Dictionary<unknown>] {
  const injectedState = merge({}, globalState, {
    moduleMeta: {
      moduleName,
      instanceName,
    },
  });

  return [injectedState, moduleOptions];
}

function mkInstanceDictionary() {
  // instanceStore is the format { moduleName: { instanceName: instance } }
  const instanceStore: Record<string, Record<string, object>> = {};

  const storeInstance = <Name extends Modules['moduleName']>(
    moduleName: Name,
    instanceName: string,
    instance: InstanceObject<object>,
  ): void => {
    instanceStore[moduleName] = instanceStore[moduleName] ?? {};
    instanceStore[moduleName][instanceName] = instance;
  };
  const retrieveInstance = <Name extends Modules['moduleName']>(
    moduleName: Name,
    instanceName: string,
  ): InstanceObject<object> | null => {
    return (instanceStore?.[moduleName]?.[instanceName] as InstanceObject<object>) ?? null;
  };

  return { storeInstance, retrieveInstance, instanceStore };
}
